<template>
  <div class="modal-wrapper">
    <div v-if="open" class="backdrop"></div>
    <Transition name="hty-drop">
      <div v-if="open" class="modal-custom d-flex justify-content-center">
        <div class="overflow-container d-flex">
          <OutsideClickContainer @outsideClick="$emit('backdropClick')" active>
            <div
              :class="
                !customStyleExist
                  ? 'modal-content my-auto'
                  : 'modal-custom-width my-auto'
              "
              :style="calcCustomStyle"
            >
              <slot />
            </div>
          </OutsideClickContainer>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import OutsideClickContainer from '../OutsideClickContainer/OutsideClickContainer.vue';

export default {
  setup(props) {
    const customStyleExist = computed(
      () => Object.keys(props.customStyle).length > 0
    );
    const calcCustomStyle = computed(() =>
      customStyleExist.value ? props.customStyle : null
    );

    return {
      calcCustomStyle,
      customStyleExist,
    };
  },
  components: {
    OutsideClickContainer,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    extraWide: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}
.overflow-container {
  height: 100%;
  overflow-y: auto;
}
.modal-custom {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 21;
  width: 100%;
  height: 100%;
  overflow: auto;
  // margin: 0 auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.modal-content {
  background-color: transparent;

  ::v-deep .modal-custom {
    @include hty-card;
    width: 760px;
    padding: 20px;
    @media (max-width: $hty-md) {
      width: 550px;
    }
    @media (max-width: $hty-sm) {
      width: 467px;
    }
    @media (max-width: $hty-xs) {
      width: 370px;
    }
  }
}

.modal-custom-width {
  ::v-deep .modal-custom {
    @include hty-card;
    width: 100%;
    height: 100%;
  }
}
</style>
